// VERSION 3
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const sections = [
  { id: 'main-about', label: 'About' },
  { id: 'main-skills', label: 'Skills' },
  { id: 'main-experience', label: 'Experience' },
  { id: 'main-education', label: 'Education' },

];


const NavBar = forwardRef((props, ref) => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  useImperativeHandle(ref, () => ({
    setActiveSection: (sectionId) => {
      setActiveSection(sectionId);
    }
  }));

  useEffect(() => {
    const handleScroll = () => {
      const sectionElements = sections.map(section => document.getElementById(section.id));
      let currentSectionId = activeSection;

      for (const section of sectionElements) {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
          currentSectionId = section.id;
          break;
        }
      }

      if (currentSectionId !== activeSection) {
        setActiveSection(currentSectionId);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeSection]);

  return (
    <div id="header-nav" ref={ref}>
      <ul>
        {sections.map(section => (
          <li key={section.id}>
            <a
              href={`#${section.id}`}
              className={activeSection === section.id ? 'active-section inner-link' : 'inner-link'}
            >
              <span className={activeSection === section.id ? 'link-line active' : 'link-line'}>
                --------------
              </span> {section.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
});

export default NavBar;


/* VERSION 2
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const sections = [
  { id: 'main-about', label: 'About' },
  { id: 'main-skills', label: 'Skills' },
  { id: 'main-experience', label: 'Experience' },
  { id: 'main-education', label: 'Education' },
  { id: 'main-referees', label: 'Referees' }
];

const NavBar = forwardRef((props, ref) => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  useImperativeHandle(ref, () => ({
    setActiveSection: (sectionId) => {
      setActiveSection(sectionId);
    }
  }));

  return (
    <div id="header-nav" ref={ref}>
      <ul>
        {sections.map(section => (
          <li key={section.id}>
            <a
              href={`#${section.id}`}
              className={activeSection === section.id ? 'active inner-link' : 'inner-link'}
            >
              <span className={activeSection === section.id ? 'link-line active' : 'link-line'}>--------------</span> {section.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
});

export default NavBar;
*/

/* VERSION 1
import React, { useState, useEffect } from 'react';

const sections = [
  { id: 'main-about', label: 'About' },
  { id: 'main-skills', label: 'Skills' },
  { id: 'main-experience', label: 'Experience' },
  { id: 'main-education', label: 'Education' },
  { id: 'main-referees', label: 'Referees' }
];

function NavBar() {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    //console.log(scrollPosition);
    const currentSection = sections.find(section => {
      const element = document.getElementById(section.id);
      return element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition;
    });

    if (currentSection) {
      setActiveSection(currentSection.id);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="header-nav">
      <ul>
        {sections.map(section => (
          <li key={section.id}>
            <a
              href={`#${section.id}`}
              className={activeSection === section.id ? 'active' : ''}
            >
              <span className="link-line">--------------</span> {section.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default NavBar;
*/