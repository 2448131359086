// VERSION 2
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const Experience = React.forwardRef(({ experience }, ref) => {

  useEffect(() => {
    // Function to handle mouse enter event
    const handleMouseEnter = (e) => {
      const allSubsections = document.querySelectorAll('.subsection');
      allSubsections.forEach((subsection) => {
        if (subsection !== e.currentTarget) {
          subsection.classList.add('fade');
        }
      });
    };

    // Function to handle mouse leave event
    const handleMouseLeave = () => {
      const allSubsections = document.querySelectorAll('.subsection');
      allSubsections.forEach((subsection) => {
        subsection.classList.remove('fade');
      });
    };

    // Attach event listeners to all subsections
    const subsections = document.querySelectorAll('.subsection');
    subsections.forEach((subsection) => {
      subsection.addEventListener('mouseenter', handleMouseEnter);
      subsection.addEventListener('mouseleave', handleMouseLeave);
    });

    // Clean up event listeners on component unmount
    return () => {
      subsections.forEach((subsection) => {
        subsection.removeEventListener('mouseenter', handleMouseEnter);
        subsection.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  return (
    <div ref={ref} id="main-experience" className="res-section viewed">
      <h2 className="res-section-header">&nbsp;<i className="fa fa-briefcase" />&nbsp;&nbsp;Experience</h2>
      <div className="res-section-inner">
        {experience.map((job, index) => (
          
            <div className="subsection" id={`${job.company.toLowerCase().replace(/\s+/g, '-')}-experience`}>
              <div className="subsection-left">
                <h4>{job.period}</h4>
              </div>
              <div className="subsection-right">
                <h3>{job.title} ⋅ {job.company} </h3>
                <p className="experience-description">{job.description}</p>
                <ul>
                  {job.responsibilities.map((responsibility, i) => (
                    <li key={i}><span>//&nbsp;&nbsp;&nbsp;</span>{responsibility}</li>
                  ))}
                </ul>
                <div class="image-container">
                  <a key={index} href={job.companyUrl} target="_blank" rel="noopener noreferrer">
                    <img
                      src={`./images/${job.preview}`}
                      alt={`${job.company}-thumbnail`}
                      className="preview-image"
                    />
                  </a>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="link-arrow"/>
                </div>
              </div>
            </div>
          
        ))}
        <hr />
        <p id="referee-explanation">
        <i className="fa fa-phone" />&nbsp;&nbsp;Referees available upon request - shoot me an <a className="alt-link" href="mailto:perry.lachlan@gmail.com">email</a>!
        </p>
      </div>
    </div>
  );
});

export default Experience;



/* VERSION 1
import React, { useEffect } from 'react';

function Experience({ experience }) {

  useEffect(() => {
    // Function to handle mouse enter event
    const handleMouseEnter = (e) => {
      const allSubsections = document.querySelectorAll('.subsection');
      allSubsections.forEach((subsection) => {
        if (subsection !== e.currentTarget) {
          subsection.classList.add('fade');
        }
      });
    };

    // Function to handle mouse leave event
    const handleMouseLeave = () => {
      const allSubsections = document.querySelectorAll('.subsection');
      allSubsections.forEach((subsection) => {
        subsection.classList.remove('fade');
      });
    };

    // Attach event listeners to all subsections
    const subsections = document.querySelectorAll('.subsection');
    subsections.forEach((subsection) => {
      subsection.addEventListener('mouseenter', handleMouseEnter);
      subsection.addEventListener('mouseleave', handleMouseLeave);
    });

    // Clean up event listeners on component unmount
    return () => {
      subsections.forEach((subsection) => {
        subsection.removeEventListener('mouseenter', handleMouseEnter);
        subsection.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  return (
    <div ref={ref} id="main-experience" className="res-section">
      <h2 className="res-section-header">&nbsp;<i className="fa fa-briefcase" />&nbsp;&nbsp;Experience</h2>
      {experience.map((job, index) => (
        <a key={index} href={job.companyUrl} target="_blank">
          <div className="subsection" id={`${job.company.toLowerCase().replace(/\s+/g, '-')}-experience`}>
            <div className="subsection-left">
              <h4>{job.period}</h4>
            </div>
            <div className="subsection-right">
              <h3>{job.title} ⋅ {job.company} <span className="lift-arrow">↗</span></h3>
              <p>{job.description}</p>
              <img
                src={`./images/${job.preview}`}
                alt={`${job.company}-thumbnail`}
                className="preview-image"
            />
              <ul>
                {job.responsibilities.map((responsibility, i) => (
                  <li key={i}>{responsibility}</li>
                ))}
              </ul>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}

export default Experience;
*/