// VERSION 2
import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import ScrollContainer from './ScrollContainer';
import NavBar from './NavBar';
import Header from './Header';

function App() {
  const headerNavRef = useRef(null);

  useEffect(() => {
    // CURSOR GLOW CODE
    const cursor = document.createElement('div');
    cursor.id = 'cursor';
    document.body.appendChild(cursor);

    const handleMouseMove = (e) => {
      cursor.style.left = `${e.clientX}px`;
      cursor.style.top = `${e.clientY + window.scrollY}px`;
    };

    document.addEventListener('mousemove', handleMouseMove);
    
    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.body.removeChild(cursor);
    };
  }, []);

  return (
    <div className="App">
      <div id="container">
        <div id="left-div">
        <Header />
        </div>
        <ScrollContainer />
      </div>
    </div>
  );
}

export default App;



/* VERSION 1
import React, { useRef, useEffect } from 'react';
import './styles.css';
import ScrollContainer from './ScrollContainer';
import NavBar from './NavBar';

function App() {
  useEffect(() => {
    // Create the custom cursor element
    const cursor = document.createElement('div');
    cursor.id = 'cursor';
    document.body.appendChild(cursor);

    // Update cursor position on mouse move
    const handleMouseMove = (e) => {
      cursor.style.left = `${e.clientX}px`;
      cursor.style.top = `${e.clientY + window.scrollY}px`;
    };

    document.addEventListener('mousemove', handleMouseMove);

    

    // Clean up event listeners and remove cursor on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.body.removeChild(cursor);
    };
  }, []);
  

  return (
    <div className="App">
      <div id="container">
        <div id="left-div">
          <header>
            <div id="header-name">
              Lachlan Perry
            </div>
            <div id="header-summary">
              Budding web developer with intuitive problem solving and exceptional relational skills - helping organizations represent themselves online with more clarity.
            </div>
            <NavBar />
            <div id="header-links">
              <a href="https://www.linkedin.com/in/lachlan-perry/" className="fa fa-linkedin"></a>
              <a href="https://github.com/asparagusman" className="fa fa-github"></a>
              <a href="mailto:perry.lachlan@gmail.com" className="fa fa-envelope"></a>
            </div>
          </header>
        </div>
        <ScrollContainer />
      </div>
    </div>
  );
}

export default App;
*/