// VERSION 2
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3Alt, faJs, faJava, faPython, faReact, faGithub, faMicrosoft, faCloudflare, faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

const iconMap = {
  faHtml5,
  faCss3Alt,
  faJs,
  faJava,
  faReact,
  faPython,
  faGithub,
  faMicrosoft,
  faCloudflare,
  faDatabase,
  faWordpress
};

function renderRating(level) {
  const rating = parseInt(level.charAt(0), 10);
  const filledCircles = Array(rating).fill().map((_, index) => <i key={`filled-${index}`} className="fa fa-circle" />);
  const emptyCircles = Array(5 - rating).fill().map((_, index) => <i key={`empty-${index}`} className="fa fa-circle-o" />);

  return [...filledCircles, ...emptyCircles];
}

const Skills = React.forwardRef(({ skills }, ref) => {
  return (
    <div id="main-skills" className="res-section viewed" ref={ref}>
      <h2 className="res-section-header"><i className="fa fa-code" />&nbsp;&nbsp;Skills</h2>
      <div className="res-section-inner">
        <h4>Languages</h4>
        <div className="skills-section">
          {skills.languages.map((language, index) => (
            <div key={index} className="skill-button">
              <FontAwesomeIcon icon={iconMap[language.icon]} className="skill-icon" /> {language.name} <span className="skill-rating">{renderRating(language.level)}</span>
            </div>
          ))}
        </div>
        <h4>Programs/Environments</h4>
        <div className="skills-section">
          {skills.programs.map((program, index) => (
            <div key={index} className="skill-button">
              <FontAwesomeIcon icon={iconMap[program.icon]} className="skill-icon" /> {program.name} <span className="skill-rating">{renderRating(program.level)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Skills;



/* VERSION 1
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3Alt, faJs, faJava, faPython, faReact, faGithub, faMicrosoft, faCloudflare} from '@fortawesome/free-brands-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

const iconMap = {
  faHtml5,
  faCss3Alt,
  faJs,
  faJava,
  faReact,
  faPython,
  faGithub,
  faMicrosoft,
  faCloudflare,
  faDatabase
};

function renderRating(level) {
  const rating = parseInt(level.charAt(0), 10);
  const filledCircles = Array(rating).fill().map((_, index) => <i key={`filled-${index}`} className="fa fa-circle" />);
  const emptyCircles = Array(5 - rating).fill().map((_, index) => <i key={`empty-${index}`} className="fa fa-circle-o" />);

  return [...filledCircles, ...emptyCircles];
}

function Skills({ skills }) {
  return (
    <div id="main-skills" className="res-section">
      <h2 className="res-section-header"><i className="fa fa-code" />&nbsp;&nbsp;Skills</h2>
      <div>
        <h4>Languages</h4>
        <div className="skills-section">
          {skills.languages.map((language, index) => (
            <div key={index} className="skill-button">
              <FontAwesomeIcon icon={iconMap[language.icon]} className="skill-icon" /> {language.name} <span className="skill-rating">{renderRating(language.level)}</span>
            </div>
          ))}
        </div>
        <h4>Programs/Environments</h4>
        <div className="skills-section">
          {skills.programs.map((program, index) => (
            <div key={index} className="skill-button">
              <FontAwesomeIcon icon={iconMap[program.icon]} className="skill-icon" /> {program.name} <span className="skill-rating">{renderRating(program.level)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Skills;
*/