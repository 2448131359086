//VERSION 2
import React from 'react';

const About = React.forwardRef((props, ref) => {
  return (
    <div id="main-about" className="res-section" ref={ref}>
      <h2 id="about-header" className="res-section-header">&nbsp;<i className="fa fa-address-card" /> About</h2>
      <div>
        <img className="image-headshot" src="./images/lachlan-headshot.jpeg" alt="Headshot" />
        <span>
          I’m a <i>quintessential</i> Melburnian with a love for coffee, <a className="alt-link" href="https://www.instagram.com/asparagus_man_/" target="_blank" rel="noopener noreferrer">food</a>, and <a className="alt-link" href="https://open.spotify.com/user/asparagusman?si=13f5b0ba0f3a4963" target="_blank" rel="noopener noreferrer">good music</a>. Someone who perfects his craft, whether that be preparing the perfect dish or a piece of code.
        </span>
        <br /><br />
        <span>
          An experienced professional with a diverse background ranging from Registrar at <a className="alt-link" href="#mst-experience">Melbourne School of Theology</a> to Service Technician at <a className="alt-link" href="#rmit-experience">RMIT ITS</a>.
        </span>
        <br /><br />
        <span>
          Shown to combine adaptability to new environments, strong teamwork skills, highly effective communication skills, a high technological literacy, with a proactive approach to work that seeks to refine and improve processes wherever I find myself: Driven by a desire to enhance productivity and increase production through better processes.
        </span>
        <br /><br />
        <span>
          Notable achievements include orchestrating an overhaul of enrolment and application processes at Melbourne School of Theology, coordinating a 50+ volunteer team and developing a unifying vision for the team to elevate its effectiveness, rapidly adapting to new challenges and problems within service roles at RMIT AV Services and ITS and firmly establishing myself within a team providing 2nd level support.
        </span>
      </div>
    </div>
  );
});

export default About;


/*
import React from 'react';

function About() {
  return (
    <div id="main-about" className="res-section">
      <h2 id="about-header">&nbsp;<i className="fa fa-address-card" /> About</h2>
      <img className="image-headshot" src="./images/lachlan-headshot.jpeg" />
      <span>
        I’m a <i>quintessential</i> Melburnian with a love for coffee, <a className="alt-link" href="https://www.instagram.com/asparagus_man_/" target="_blank">food</a>, and <a className="alt-link" href="https://open.spotify.com/user/asparagusman?si=13f5b0ba0f3a4963" target="_blank">good music</a>. Someone who perfects his craft, whether that be preparing the perfect dish or a piece of code.
      </span>
      <br /><br />
      <span>
        An experienced professional with a diverse background ranging from Registrar at <a className="alt-link" href="#mst-experience">Melbourne School of Theology</a> to Service Technician at <a className="alt-link" href="#rmit-experience">RMIT ITS</a>.
      </span>
      <br /><br />
      <span>
        Shown to combine adaptability to new environments, strong teamwork skills, highly effective communication skills, a high technological literacy, with a proactive approach to work that seeks to refine and improve processes wherever I find myself: Driven by a desire to enhance productivity and increase production through better processes.
      </span>
      <br /><br />
      <span>
        Notable achievements include orchestrating an overhaul of enrolment and application processes at Melbourne School of Theology, coordinating a 50+ volunteer team and developing a unifying vision for the team to elevate its effectiveness, rapidly adapting to new challenges and problems within service roles at RMIT AV Services and ITS and firmly establishing myself within a team providing 2nd level support.
      </span>
    </div>
  );
}

export default About;
*/