// VERSION 3
import React, { useState, useEffect, useRef } from 'react';
import About from './components/About';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Education from './components/Education';
import resumeData from './resumeData.json';

function ScrollContainer() {
  const [data, setData] = useState(null);

  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const experienceRef = useRef(null);
  const educationRef = useRef(null);

  const config = {
    root: document.body,
    rootMargin: '-100px 0 px',
    threshold: [...Array(30).keys()].map(x => x / 29)
  };
  
  useEffect(() => {
    setData(resumeData);

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

  
    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        //if (entry.isIntersecting) {
          entry.target.classList.add('viewed');
          observer.unobserve(entry.target);
        //}
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    const sections = [aboutRef, skillsRef, experienceRef, educationRef];

    sections.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    const handleRefresh = () => {
      sections.forEach(ref => {
        if (ref.current) {
          ref.current.classList.remove('viewed');
          observer.observe(ref.current);
        }
      });
    };

    window.addEventListener('load', handleRefresh);

    return () => {
      observer.disconnect();
      window.removeEventListener('load', handleRefresh);
    };
  }, []);


  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div id="scroll-container">
      <main id="main">
        <About ref={aboutRef}/>
        <Skills skills={data.skills} ref={skillsRef} />
        <Experience experience={data.experience} ref={experienceRef} />
        <Education education={data.education} ref={educationRef} />
      </main>
      <footer>
        Design inspired by <a className="alt-link" href="https://brittanychiang.com/" target="_blank" rel="noopener noreferrer">Brittany Chiang</a>, adapted and written from scratch in HTML, CSS, React. Text on <a className="alt-link" href="https://rsms.me/inter/" target="_blank" rel="noopener noreferrer">Inter</a> typeface.
      </footer>
    </div>
  );
}

export default ScrollContainer;


/* VERSION 2
import React, { useState, useEffect, useRef } from 'react';
import About from './components/About';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Education from './components/Education';
import Referees from './components/Referees';
import resumeData from './resumeData.json';

function ScrollContainer() {
  const [data, setData] = useState(null);
  
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const experienceRef = useRef(null);
  const educationRef = useRef(null);
  const refereesRef = useRef(null);

  useEffect(() => {
    setData(resumeData);

    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.1, 
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('viewed');
        } 
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    const sections = [aboutRef, skillsRef, experienceRef, educationRef];

    sections.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div id="scroll-container">
      <main id="main">
        <About ref={aboutRef}/>
        <Skills skills={data.skills} ref={skillsRef} />
        <Experience experience={data.experience} ref={experienceRef} />
        <Education education={data.education} ref={educationRef}/>
      </main>
      <footer>
        Design inspired by <a className="alt-link" href="https://brittanychiang.com/" target="_blank" rel="noopener noreferrer">Brittany Chiang</a>, adapted and written from scratch in HTML, CSS, React. Text on <a className="alt-link" href="https://rsms.me/inter/" target="_blank" rel="noopener noreferrer">Inter</a> typeface.
      </footer>
    </div>
  );
}

export default ScrollContainer;



/* VERSION 1
import React, { useState, useEffect } from 'react';
import About from './components/About';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Education from './components/Education';
import Referees from './components/Referees';
import resumeData from './resumeData.json';

// Skills above experience.
function ScrollContainer() {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(resumeData);
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div id="scroll-container">
      <main id="main">
        <About />
        <Skills skills={data.skills} />
        <Experience experience={data.experience} />
        <Education education={data.education} />
        <Referees />
      </main>
      <footer>
        Design inspired by <a className="alt-link" href="https://brittanychiang.com/" target="_blank">Brittany Chiang</a>, adapted and written from scratch in HTML, CSS, React. Text on <a className="alt-link" href="https://rsms.me/inter/" target="_blank">Inter</a> typeface.
      </footer>
    </div>
  );
}

export default ScrollContainer;
*/