// VERSION 2
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Education = React.forwardRef(({ education }, ref) => {
  return (
    <div id="main-education" className="res-section viewed" ref={ref}>
      <h2 className="res-section-header">&nbsp;<i className="fa fa-graduation-cap" />&nbsp;&nbsp;Education</h2>
      <div className="res-section-inner">
        {education.map((edu, index) => (
          <a key={index} href={edu.link} target="_blank" rel="noopener noreferrer">
            <div className="subsection">
              <div className="subsection-left">
                <h4>{edu.year}</h4>
              </div>
              <div className="subsection-right">
                <h3>{edu.institution} <FontAwesomeIcon icon={faArrowRight} /></h3>
                <h4>{edu.degree}</h4>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
});

export default Education;



/* VERSION 1
import React from 'react';

function Education({ education }) {
  return (
    <div id="main-education" className="res-section">
      <h2 className="res-section-header">&nbsp;<i className="fa fa-graduation-cap" />&nbsp;&nbsp;Education</h2>
      {education.map((edu, index) => (
        <a key={index} href={edu.link} target="_blank">
        <div className="subsection">
          <div className="subsection-left">
            <h4>{edu.year}</h4>
          </div>
          <div className="subsection-right">
            <h3>{edu.institution} <span className="lift-arrow">↗</span></h3>
            <h4>{edu.degree}</h4>
          </div>
        </div>
        </a>
      ))}
    </div>
  );
}

export default Education;
*/