import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 60) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`persistent-header ${scrolled ? 'scrolled' : ''}`}>
        <div id="header-name">
            Lachlan Perry
        </div>
        <div id="header-summary">
            Budding web developer with intuitive problem solving and exceptional relational skills - helping organizations represent themselves online with more clarity.
        </div>
        <NavBar />
        <div id="header-links">
            <a href="https://www.linkedin.com/in/lachlan-perry/" target="_blank" className="fa fa-linkedin"></a>
            <a href="https://github.com/asparagusman" target="_blank" className="fa fa-github"></a>
            <a href="mailto:perry.lachlan@gmail.com" target="_blank" className="fa fa-envelope"></a>
            <a href="https://codepen.io/asparagusman" target="_blank"className="fa fa-codepen"></a>
        </div>
    </header>
  );
};

export default Header;
